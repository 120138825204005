.html{
  background: #f1f1f1;
  &:after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-right: 140px solid transparent;
    border-top: 330px solid #f1f1f1;
    top: 0;
    right: -140px;
    z-index: 20;
  }
}
.javascript{
  background: #cccccc;
  z-index: 9 !important;
}
.frontend-build{
  background: #444444;
  color:#fff;
}
.backend{
  background: #ffffff;
  color: #000;
  &:after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 140px solid transparent;
    border-bottom: 330px solid #ffffff;
    top: 0;
    z-index: 20;
    left: -140px;
  }
}

.logo-text{
  margin: 60px auto 100px;
  display: block;

}

.overlay-row{
  height: 300px;
  margin-top: -300px;
}

.loader-container,.fade-out-container {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 200;
  top: 0;
  padding-top: 117px;
  background: #ffffff;
  color: #000000;
  font-size: 30px;
  transition: 800ms ease-in-out all;
  opacity:0;
  &.active{
    opacity: 1;
  }
  .spinner-logo{
    position: absolute;
    top: 44vh;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
}

.lnt .loader-container{
  .loader{
    border-left-color: $lnt-blue;
    border-top-color:$lnt-blue;
    border-bottom-color: $lnt-blue;
    border-right-color: #7995d3;
  }
}

.covervidz .loader-container{
  .loader{
     border-left-color: $covervidz-purple;
     border-top-color:$covervidz-purple;
     border-bottom-color: $covervidz-purple;
     border-right-color: #420d7d;
  }
}
.tvp .loader-container{
  .loader{
    border-left-color: $tvp-red;
    border-top-color: $tvp-red;
    border-bottom-color: $tvp-red;
    border-right-color: #ff0000;
  }

  .loader-bg{
    background-size: 90% !important;
  }
}

.lt .loader-container{
  .loader{
    border-left-color: $leshia-yellow;
    border-top-color: $leshia-yellow;
    border-bottom-color: $leshia-yellow;
    border-right-color: #ffd284;
  }
}
.vantworks .loader-container{
  .loader{
    border-left-color: $vantworks-grey;
    border-top-color: $vantworks-grey;
    border-bottom-color: $vantworks-grey;
    border-right-color: #a3a3a3;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 14em;
  height: 14em;
}
.loader {
  font-size: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 35vh;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(159, 159, 159, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(159, 159, 159, 0.2);
  border-left: 1.1em solid rgba(159, 159, 159, 0.2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: 10;
}

.loader-bg{
  font-size: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 35vh;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(159, 159, 159, 0);
  border-right: 1.1em solid rgba(0, 0, 0, 0);
  border-bottom: 1.1em solid rgba(159, 159, 159, 0);
  border-left: 1.1em solid rgba(159, 159, 159, 0);
  background: red;
  width: 14em;
  height: 14em;
  border-radius: 50%;
  background-size: 90% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.promo-lnt{
  img{
    box-shadow: 0 4px 8px 0 rgba(141, 141, 141, 0.4);
  }
}
.promo-tvp{
  img{
    box-shadow: 0 4px 8px 0 #9c1717;
  }
}

.what-i-do-wrap{
  height: 0px;
  overflow: hidden;
  transition: ease-in-out 300ms all;
  background: #eee;
  &.in {
    height: 320px;
  }
  @media(max-width: 991px) {
    &.in {
      height: 480px;
    }
  }
  @media(max-width: 767px) {
    &.in {
      height: 570px;
    }
  }
}

#text-carousel {
  max-width: 500px;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  @include absoluteCenter();
  top: 360px;
  z-index: 100;
  .item {
    text-align: center;
  }
}

.letter-header{
  max-width: 400px;
}

.ocado-section{
  margin-bottom: 50px;
  text-align: center;
}

.lnt-bg .feature-row img.screenshot {
  box-shadow: 0 0 2px #9a9a9a;
}

.mw-feature-image-row {
  padding: 0;

  img {
    margin-top: -200px;
    max-width: 130%;
    width: 130%; 
  }
}

.lnt-bg .header-row img {
  max-width: 300px;
  margin: 0 auto;
}



