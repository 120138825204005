.page-container{
  box-shadow: 0 0 8px 2px rgba(0,0,0,.08);
}

.home-bg-blue{
  background: #628fbc;
  h3{
    color: #12456f;
  }
}

.lnt-bg-blue{
  background: -moz-linear-gradient(90deg, rgba(184,201,211,1) 0%, rgba(255,255,255,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(184,201,211,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, rgba(184,201,211,1) 0%, rgba(255,255,255,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, rgba(184,201,211,1) 0%, rgba(255,255,255,1) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, rgba(184,201,211,1) 0%, rgba(255,255,255,1) 100%); /* ie10+ */
  background: linear-gradient(0deg, rgba(184,201,211,1) 0%, rgba(255,255,255,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#b8c9d3',GradientType=0 ); /* ie6-9 */

}

.lnt-solid-bg{
  background: $lnt-blue;
}

.vantworks-bg{
  background: -moz-linear-gradient(90deg, rgba(210,194,195,1) 0%, rgba(210,194,195,1) 51%, rgba(255,255,255,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(49%, rgba(210,194,195,1)), color-stop(100%, rgba(210,194,195,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, rgba(210,194,195,1) 0%, rgba(210,194,195,1) 51%, rgba(255,255,255,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, rgba(210,194,195,1) 0%, rgba(210,194,195,1) 51%, rgba(255,255,255,1) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, rgba(210,194,195,1) 0%, rgba(210,194,195,1) 51%, rgba(255,255,255,1) 100%); /* ie10+ */
  background: linear-gradient(0deg, rgba(210,194,195,1) 0%, rgba(210,194,195,1) 51%, rgba(255,255,255,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d2c2c3',GradientType=0 ); /* ie6-9 */
}

.vantworks-solid-bg{
  background: $vantworks-grey;
}

.leshia-bg{
  background: -moz-linear-gradient(90deg, rgba(252,227,170,1) 0%, rgba(255,255,255,1) 100%); /* ff3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(252,227,170,1))); /* safari4+,chrome */
  background: -webkit-linear-gradient(90deg, rgba(252,227,170,1) 0%, rgba(255,255,255,1) 100%); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(90deg, rgba(252,227,170,1) 0%, rgba(255,255,255,1) 100%); /* opera 11.10+ */
  background: -ms-linear-gradient(90deg, rgba(252,227,170,1) 0%, rgba(255,255,255,1) 100%); /* ie10+ */
  background: linear-gradient(0deg, rgba(252,227,170,1) 0%, rgba(255,255,255,1) 100%); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fce3aa',GradientType=0 ); /* ie6-9 */
}

.leshia-solid-bg{
  background: $leshia-yellow;
}

.tvp-red-bg {
  background: rgba(143,2,34,1);
  background: -moz-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(254,73,78,1) 34%, rgba(254,73,78,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143,2,34,1)), color-stop(0%, rgba(255,255,255,1)), color-stop(34%, rgba(254,73,78,1)), color-stop(100%, rgba(254,73,78,1)));
  background: -webkit-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(254,73,78,1) 34%, rgba(254,73,78,1) 100%);
  background: -o-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(254,73,78,1) 34%, rgba(254,73,78,1) 100%);
  background: -ms-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(254,73,78,1) 34%, rgba(254,73,78,1) 100%);
  background: linear-gradient(to bottom, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(254,73,78,1) 34%, rgba(254,73,78,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#fe494e', GradientType=0 );
}

.tvp-solid-bg{
  background: $tvp-red;
}

.covervidz-bg{
  background: rgba(143,2,34,1);
  background: -moz-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(93,63,125,1) 34%, rgba(93,63,125,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143,2,34,1)), color-stop(0%, rgba(255,255,255,1)), color-stop(34%, rgba(93,63,125,1)), color-stop(100%, rgba(93,63,125,1)));
  background: -webkit-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(93,63,125,1) 34%, rgba(93,63,125,1) 100%);
  background: -o-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(93,63,125,1) 34%, rgba(93,63,125,1) 100%);
  background: -ms-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(93,63,125,1) 34%, rgba(93,63,125,1) 100%);
  background: linear-gradient(to bottom, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(93,63,125,1) 34%, rgba(93,63,125,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#5d3f7d', GradientType=0 );
}

.covervidz-solid-bg, .covervidz-solid-bg .loader-container{
  background: #ae90ce;
}

.covervidz-bg,.covervidz-solid-bg{
  color: #ffffff;
}

.simplestream-blue-bg{
  background: rgba(143,2,34,1);
  background: -moz-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(24,100,158,1) 59%, rgba(24,100,158,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143,2,34,1)), color-stop(0%, rgba(255,255,255,1)), color-stop(59%, rgba(24,100,158,1)), color-stop(100%, rgba(24,100,158,1)));
  background: -webkit-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(24,100,158,1) 59%, rgba(24,100,158,1) 100%);
  background: -o-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(24,100,158,1) 59%, rgba(24,100,158,1) 100%);
  background: -ms-linear-gradient(top, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(24,100,158,1) 59%, rgba(24,100,158,1) 100%);
  background: linear-gradient(to bottom, rgba(143,2,34,1) 0%, rgba(255,255,255,1) 0%, rgba(24,100,158,1) 59%, rgba(24,100,158,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f0222', endColorstr='#18649e', GradientType=0 );
}

.simplestream-solid-bg{
  background: #18649E;
}

.simplestream-blue-bg, .simplestream-solid-bg{
  color:#fff;
}

.tvp-red-bg, .tvp-solid-bg{
  color: #ffffff;
  p,h2{
    opacity: 0.95;
  }
}


.promo-row{
  background: url('../../img/slanted-tvp-web.jpg');
  background-size: cover;
  background-position: center top;
  height:500px;
}

.vantworks-promo-top{
  margin-top: 70px;
}

.page-wrap{
  min-height: 100%;
  padding-bottom: 200px;
  position: relative;
}

.about{
  padding: 50px 0 45px;
  color: #ffffff;
  margin-top: -20px;
  margin-bottom: -5px;
  p{
    font-size: 17px;
  }
}

.bodhan-bg .container {
  background: rgb(230,221,229);
  background: -moz-linear-gradient(180deg, rgba(230,221,229,1) 0%, rgba(216,201,221,1) 100%, rgba(216,201,221,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(230,221,229,1) 0%, rgba(216,201,221,1) 100%, rgba(216,201,221,1) 100%);
  background: linear-gradient(180deg, rgba(230,221,229,1) 0%, rgba(216,201,221,1) 100%, rgba(216,201,221,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e6dde5",endColorstr="#d8c9dd",GradientType=1);;
}

.windrush-bg, .windrush-bg .loader-container {
  background: #edd99f;
}

.bershka-bg {
  background: #231f20;
  color: #ffffff;
}

.bershka-bg .loader-container {
  background: #231f20;
}

.shotgun-bg, .shotgun-bg .loader-container {
  background: #e0f8ff;
}

.om-bg, .om-bg .loader-container {
  background: #4b57ab;
  color: #ffffff;
}

.lefties-bg {
  background: #35495a;
  color: #fff;
} 

.lnt-bg {
//  @include lntGradient();
  background: #edc2c9;
}

.lnt-bg .loader-container {
  background: #edc2c9;
}

.darling-bg, .darling-bg .loader-container {
  background: #eca9c8;
}
.harvest-bg, .harvest-bg .loader-container {
  background: #59585b;
  color: #ffffff;
}

.pull-and-bear-bg, .pull-and-bear-bg .loader-container {
  background: #e3f2e8;
}

.info-col{
  background: #f5df4a;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 300ms ease-in-out all;

  &:hover {
    background: #fbeb80;
  }

  a, a:hover, a:focus {
    color: #444444;
  }
}

.bg-white {
  background: #ffffff;
}

#home {
  box-shadow: 0 0 3px #909090;
}

.what-i-do-row{
  padding-top: 30px;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.feature-row, .header-row {
  display: flex;
  align-items: center;
  position: relative;
}

.feature-row {
  padding: 50px 0;
}

.header-row {
  padding: 120px 0;
}

.footer-text-row {
  padding: 120px 0;
}

.page-wrap .container {
  box-shadow: 0 0 1px #e6e6e6;
}

.shotgun-page .container {
  @include shotgunGradient();
}

.ad-page .container {
  @include adGradient();
  color: #fff;
}

.mw-page .container{
  overflow: hidden;
}

.ad-page .loader-container{
  background: $ad-bg-color;
}

.cls-page .container, .cls-page .loader-container {
  background: #e8e6e6;
}

.ocado-overlapping-text-col {
  position: absolute;
  z-index: 10;
  max-width: 320px;

  &.one {
    margin-left: 30px;
    top: 265px;
  }

  &.two {
    right: 30px;
    top: 215px;
    right: 90px;
  }

  &.three {
    top: 100px;
    left: 30px;
  }
}

.lnt-typography.feature-row {
  > div {
    align-self: flex-start;
  }
}



