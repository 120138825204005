$light-grey-1:#f1f1f1;
$tvp-red:#FE494E;
$covervidz-purple: #a190c5;
$leshia-yellow: #fce2a9;
$lnt-blue: #b8c9d3;
$vantworks-grey:#d2c2c3;
$om-bg-color: #14103c;
$darling-bg-color: #cd1c6b;
$harvest-bg-color: #2b243d;
$bodhan-bg-color: #ecdbeb;
$bershka-bg-color: #621144;
$pull-and-bear-bg-color: #c684b9;
$windrush-bg-color: #a8c6dd;
$shotgun-bg-color:  #e0f8ff;
$lefties-bg-color: #35495a;
$ad-bg-color: #17243e;
