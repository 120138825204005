.header{
  min-height: 660px;
  padding-top: 240px;
  position: relative;
  text-align: center;
  color:#000;
  text-transform: uppercase;
  h1{
    font-size: 60px;
    margin-bottom: 10px;
    margin-top: 48px;
    z-index: 20;
    @include absoluteCenter();
    small {
      font-size: 20px;
    }
  }

  img.logo{
    width:70px;
  }
  .overlay{
    display: none;
    background: rgba(47, 46, 46, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    top: 0;
  }
}

.tvp-header{
  margin-top: 100px;
  margin-bottom: 40px;
}

.lnt-header-logo{
  margin-top: 100px;
}

.leshia-header-text{
  padding: 100px 0 0;
}

.vantworks-header{
  margin-top: 70px;
}

.page-header-logo {
  margin: 20px auto;
  width: 300px;
}

.skills-header {
  margin: 15px auto 0;
  width: 130px;
  padding-bottom: 10px;
}