.px-0 {
  padding-left: 0!important;
  padding-right: 0!important
}

.px-10 {
  padding-left: 10px!important;
  padding-right: 10px!important
}

.px-20 {
  padding-left: 20px!important;
  padding-right: 20px!important
}

.px-30 {
  padding-left: 30px!important;
  padding-right: 30px!important
}

.px-40 {
  padding-left: 40px!important;
  padding-right: 40px!important
}

.px-50 {
  padding-left: 50px!important;
  padding-right: 50px!important
}

.py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important
}

.py-10 {
  padding-top: 10px!important;
  padding-bottom: 10px!important
}

.py-20 {
  padding-top: 20px!important;
  padding-bottom: 20px!important
}

.py-30 {
  padding-top: 30px!important;
  padding-bottom: 30px!important
}

.py-40 {
  padding-top: 40px!important;
  padding-bottom: 40px!important
}

.py-50 {
  padding-top: 50px!important;
  padding-bottom: 50px!important
}

.pt-0 {
  padding-top: 0!important
}

.pt-10 {
  padding-top: 10px!important
}

.pt-20 {
  padding-top: 20px!important
}

.pt-30 {
  padding-top: 30px!important
}

.pt-40 {
  padding-top: 40px!important
}

.pt-50 {
  padding-top: 50px!important
}

.pb-0 {
  padding-bottom: 0px!important
}

.pb-10 {
  padding-bottom: 10px!important
}

.pb-20 {
  padding-bottom: 20px!important
}

.pb-30 {
  padding-bottom: 30px!important
}

.pb-40 {
  padding-bottom: 40px!important
}

.pb-50 {
  padding-bottom: 50px!important
}

.pl-0 {
  padding-left: 0!important
}

.pl-10 {
  padding-left: 10px!important
}

.pl-20 {
  padding-left: 20px!important
}

.pl-30 {
  padding-left: 30px!important
}

.pl-40 {
  padding-left: 40px!important
}

.pl-50 {
  padding-left: 50px!important
}

.pr-0 {
  padding-right: 0!important
}

.pr-10 {
  padding-right: 10px!important
}

.pr-20 {
  padding-right: 20px!important
}

.pr-30 {
  padding-right: 30px!important
}

.pr-40 {
  padding-right: 40px!important
}

.pr-50 {
  padding-right: 50px!important
}

.mt-0 {
  margin-top: 0!important
}
.mt-10 {
  margin-top: 10px!important
}

.mt-20 {
  margin-top: 20px!important
}

.mt-30 {
  margin-top: 30px!important
}

.mt-40 {
  margin-top: 40px!important
}

.mt-50 {
  margin-top: 50px!important
}

.mb-10 {
  margin-bottom: 10px!important
}

.mb-20 {
  margin-bottom: 20px!important
}

.mb-30 {
  margin-bottom: 30px!important
}

.mb-40 {
  margin-bottom: 40px!important
}

.mb-50 {
  margin-bottom: 50px!important
}

.ml-10 {
  margin-left: 10px!important
}

.ml-20 {
  margin-left: 20px!important
}

.ml-30 {
  margin-left: 30px!important
}

.ml-40 {
  margin-left: 40px!important
}

.ml-50 {
  margin-left: 50px!important
}

.mr-10 {
  margin-right: 10px!important
}

.mr-20 {
  margin-right: 20px!important
}

.mr-30 {
  margin-right: 30px!important
}

.mr-40 {
  margin-right: 40px!important
}

.mr-50 {
  margin-right: 50px!important
}

.mt-min-10 {
  margin-top: -10px
}

.center{
  margin: 0 auto;
  display: block;
}

.no-padding-top{
  padding-top: 0 !important;
}