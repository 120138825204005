.lt-icon {
  width: 100px;
  border-radius: 10px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 46vh;
}

.contact-link, .contact-link:hover, .contact-link:focus {
  color: #999;
  font-size: 18px;
  cursor: pointer;
}

.info-btn {
  background: #cd1c6b;
  border-radius: 0;
  color: #ffffff;
  height: 47px;
  margin-bottom: 80px;
  font-size: 20px;
  border-radius: 2px;
  transition: 300ms ease-in-out all;
  &:hover {
    background-color: #ea5799;
    color: #ffffff;
  }
}

.info-col{
  font-size: 18px;
}