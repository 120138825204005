html, body{
  height: 100%;
}

body{
  font-family: 'Lato', sans-serif;
}

a{
  &:hover,&:focus{
    text-decoration: none !important;
  }
}

p {
  opacity: 0.8;
}

