@mixin absoluteCenter() {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@mixin headerGradient() {
  background: rgba(250,250,250,1);
  background: -moz-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 22%, rgba(68,68,68,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(250,250,250,1)), color-stop(22%, rgba(250,250,250,1)), color-stop(100%, rgba(68,68,68,1)));
  background: -webkit-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 22%, rgba(68,68,68,1) 100%);
  background: -o-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 22%, rgba(68,68,68,1) 100%);
  background: -ms-linear-gradient(left, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 22%, rgba(68,68,68,1) 100%);
  background: linear-gradient(to right, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 22%, rgba(68,68,68,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#444444', GradientType=1 );
}

@mixin shotgunGradient() {
  background: rgb(255,255,255);
background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(224,248,255,1) 49%, rgba(224,248,255,1) 100%);
background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(224,248,255,1) 49%, rgba(224,248,255,1) 100%);
background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(224,248,255,1) 49%, rgba(224,248,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#e0f8ff",GradientType=1);
}

@mixin adGradient() {
  background: rgb(23,36,62);
  background: -moz-linear-gradient(90deg, rgba(23,36,62,1) 0%, rgba(23,36,62,1) 69%, rgba(1,2,3,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(23,36,62,1) 0%, rgba(23,36,62,1) 69%, rgba(1,2,3,1) 100%);
  background: linear-gradient(90deg, rgba(23,36,62,1) 0%, rgba(23,36,62,1) 69%, rgba(1,2,3,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#17243e",endColorstr="#010203",GradientType=1);
}

@mixin lntGradient() {
  background: rgb(255,255,255);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,194,201,1) 34%, rgba(237,194,201,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,194,201,1) 34%, rgba(237,194,201,1) 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,194,201,1) 34%, rgba(237,194,201,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#edc2c9",GradientType=1);
}