.row.footer{
  height: 200px;
  bottom:0;
  padding: 66px 50px;
  background: #ffffff;
  color: #000000;
  position: absolute;
  width:100%;
  margin: 0;
  border-top: 1px solid #d0c7c7;
  text-transform: uppercase;
   a, i{
    color: #000000;
   }

   i{
     font-size: 22px;
   }

  .footer-home-link{
    color: #ffffff;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    &:hover{
      color: gold;
    }
  }

  h1{
    font-size: 40px;
    margin-top: 8px;
    margin-bottom: -8px;
  }
  li{
    a{
      color: #fff;
      transition: all ease-in-out 300ms;
      &:hover{
        color: gold;
      }
    }
  }

  .leshia-link{
    color: #cccccc;
    transition: all ease-in-out 300ms;
    &:hover{
      color: gold;
    }
  }
}

