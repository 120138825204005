.task-main-header{
  font-size: 80px;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
}

.vantworks-header{
  h1,img{
    display: inline-block;
    font-family: helvetica, sans-serif;
  }
}

.vantworks-logo-grid{
  h5{
    font-size: 2.5em;
    color: #2f2f2f;
    font-weight: 400;
    font-family: helvetica, 'sans-serif';
    display: inline-block;
  }

}

.intro-col {
  & > h2, & > p {
    text-align: left;
  }
}

.shotgun-page .feature-row, .shotgun-page .header-row {
  h2 {
    text-transform: capitalize;
    font-family: 'Arvo', serif;
  }
}

.lnt-bg .feature-row, .lnt-bg .header-row {
  h2 {
    text-transform: capitalize;
    font-family: 'Cinzel', serif;
    font-weight: 700;
  }
}

.ocado-page .feature-row, .ocado-page .header-row {
  h2 {
    text-transform: capitalize;
    font-family: 'Lora', serif;
    font-weight: 700;
    font-size: 40px;
    color: #DB52A3;
  }
}

.lnt-typography.feature-row {
  h2{
    margin-top: 0;
  }
}