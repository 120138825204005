//Responsive styles

@media(max-width: 1700px){
  .container{
    width:100%;
  }
}

@media(min-width: 1700px){
  .container{
    width:1350px;
  }
}
@media(min-width: 767px){
  .bodhan-devices{
    margin-left: -23px;
  }
  .covervidz-devices{
    margin-left: -42px;
  }
  .lnt-devices{
    margin-left: 50px;
  }
}

@media(max-width: 767px){
  #text-carousel {
    top: 390px;
  }

  .nav-toggle.active {
    right: 255px;
  }
  .grid-images{
    .col-md-6,.col-md-3{
      padding: 0;
      & > div{
        margin: 0;
      }
    }
  }

  .header h1{
    font-size: 50px;
    text-align: center;
  }

  .promo-lnt{
    .col-md-5{
      padding-top: 30px;
    }
  }
  .lower-small-image{
    padding-top: 30px;
  }

  .feature-row, .header-row, .header-text-row, .footer-text-row {
    flex-direction: column;

    &.reverse {
      flex-direction: column-reverse;
    }
  }

  .feature-row {
    padding: 25px 0;
  }

  .header-row, .header-text-row, .footer-text-row {
    padding: 20px 0;
  }

  .lnt-typography.feature-row {
    > div:nth-child(2) {
       margin-top: 20px;
    }
  }
}

@media(max-width: 991px){
  .row.footer{
    padding: 0;
    text-align: center;
    ul{
      text-align: center;
    }
  }

  .ocado-overlapping-text-col{
    position: relative;
    margin: 0 !important;
    max-width: none;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }

  .ocado-page .feature-row {
    display: block;

    > div {
      margin-top: 30px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .lnt-bg .header-row {
    display: block;
  }
}

@media (max-width: 1040px){
  .home .header {
    background-position: center center !important;
    background-repeat: no-repeat;
    background-size: cover;
}
}