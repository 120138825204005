.portfolio{
  background: #eee;
  .col-md-12{
    padding: 0;
  }
}
.tile{
  height: 390px;
  padding: 0;
  overflow: hidden;
  opacity: 1;
  transition: all ease-in-out 300ms;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
  filter: grayscale(50%);
  text-transform: uppercase;

  &:hover{
    opacity: 1;
    .title-wrap{
      opacity: 1;
    }
  }
  .title-wrap{
    position: absolute;
    top: 0;
    z-index: 21;
    color: #444;
    padding: 20px;
    background: rgba(255, 255, 255, 0.84);;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 200ms;
    text-align: center;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
     small {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      > p {
        line-height: 1.4em;
      }
     }

  }

  img {
    width:190px;
    margin: 0 auto;
  }

  &.bodhan img {
    width: 125px;
  }

  &.vantworks{
    background: #d2c2c2;
    img{
      width: 40px;
      display: inline-block;
      margin-top: -18px;
    }
  }

  &.tvplayer{
    background: #fdf5d0;
    &.two{
      background: #faaaac;
    }
    &.three{
      background: #baefba;
    }
    &.four{
      background: #b9b5a0;
    }
    img{
      margin: 0 auto;
      width:35%;
    }
  }

  &.ss{
    background: #ffffff;
    img{

    }
  }

  &.leshia{
    background: #f5d4d5;
    img{
      margin: 5px auto;

    }
  }
  
  &.tech{
    cursor: default;
    display: block;
    padding-top: 80px;
     h1{
       padding-left: 15px;
     }

    ul{
      padding: 15px;
      li{
        width:100px;
      }
    }
  }

  &.windrush {
    background: $windrush-bg-color;
  }

  &.shotgun {
    background: $shotgun-bg-color;
  }

  &.om {
    background: $om-bg-color;
  }

  &.bodhan {
    background: $bodhan-bg-color;
  }

  &.lnt {
    background: #c4b8db;
  }

  &.covervidz {
    background: $shotgun-bg-color;
  }

  &.harvest {
    background: $harvest-bg-color;
  }

  &.darling {
    background: $darling-bg-color;
  }

  &.bershka {
    background: $bershka-bg-color;
  }

  &.pull-and-bear {
    background: $pull-and-bear-bg-color;
  }

  &.lefties {
    background: $lefties-bg-color;
  }

  &.ad{
    background: $ad-bg-color;
  }

  &.mw {
    background: #fff;
  }

  &.ocado {
    background: #922359;
  }

  &.contact {
    background: #fff;
    h2 {
      color: #444;
    }
  }

  &.home-tile{
    background: #ffffff;
    h3{
      color: #444444;
    }
  }
}

.leshia.grid-images .row{
  margin-top: 0;
}

.ocado-tiles > div {
  margin-top: 15px;
  margin-bottom: 15px;
}

.ocado-promo-box {
  width: 33%;
  display: inline-block;
  padding: 5px;
}

.ocado-grid-header {
  padding: 20px 20px 5px;
}

.previous-label, .next-label {
  display: none;
}

.previous > .tile .previous-label {
  display: block;
}

.next > .tile .next-label {
  display: block;
}

